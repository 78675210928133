<template>
    <b-modal v-model="showModal" id="modal-prevent-closing" ref="modal" :title="title" @show="updateModal"
        @hidden="$emit('on-close')" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">

            <p>Banner EN</p>
            <VueFileAgent ref="vueFileAgent" v-model="images" v-model:rawModelValue="images" :theme="'grid'" :multiple="false" :meta="false"
                :accept="'image/*'" :maxSize="'3MB'" :maxFiles="1" :helpText="'Choose image file'" :errorText="{
                    type: 'Invalid file type. Only images are allowed',
                    size: 'Files should not exceed 3MB in size',
                }" @beforedelete="onBeforeDeleteImage($event)" @select="filesSelectedImage($event)" required>
            </VueFileAgent>
            <p class="mt-3">Banner ID</p>
            <VueFileAgent ref="vueFileAgent" v-model:modelValue="idImages" v-model:rawModelValue="idImages" :theme="'grid'" :multiple="false" :meta="false"
                :accept="'image/*'" :maxSize="'3MB'" :maxFiles="1" :helpText="'Choose image file'" :errorText="{
                    type: 'Invalid file type. Only images are allowed',
                    size: 'Files should not exceed 3MB in size',
                }" @beforedelete="onBeforeDeleteIdImage($event)" @select="filesSelectedIdImage($event)" required>
            </VueFileAgent>

            <b-form-group class="mt-3" label="Link" label-for="value-link">
                <b-form-input id="value-link" v-model="form.link">
                </b-form-input>
            </b-form-group>

            <b-form-group class="mt-3" label="Priority (Higher priority will appear first)" label-for="value-input">
                <b-form-input id="value-input" type="number" v-model="form.priority" min="0" max="1000">
                </b-form-input>
            </b-form-group>

            <b-form-group class="mt-3" label="Display" label-for="value-input">
                <b-form-radio v-model="form.platform" :value="null" class="mt-2">All</b-form-radio>
                <b-form-radio v-model="form.platform" value="web" class="mt-2">Web only</b-form-radio>
                <b-form-radio v-model="form.platform" value="mobile" class="mt-2">Mobile only</b-form-radio>
            </b-form-group>
        </form>
    </b-modal>
</template>

<script>

import { getBase64 } from "../../../helpers/common"
import bannerService from "../../../services/admin/banner.service"

export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        banner: {
            type: Object,
            default: null,
        },
    },
    emits: ['on-close'],
    data() {
        return {
            form: {
                priority: 0,
            },
            images: [],
            idImages: [],
            showModal: false,
        }
    },

    watch: {
        show(value) {
            this.showModal = value
        }
    },

    methods: {
        updateModal() {
            if (this.banner) {
                const name = this.banner.image.split("/").pop()
                const type = this.banner.image.split(".").pop()
                const images = [
                    {
                        id: this.banner.id,
                        name: name,
                        tempName: name,
                        url: this.banner.image,
                        path: this.banner.image,
                        size: 0,
                        type: `image/${type}`,
                    }
                ]
                this.images = images

                if (this.banner.image_id_locale) {
                    const name = this.banner.image_id_locale.split("/").pop()
                    const type = this.banner.image_id_locale.split(".").pop()
                    const idImages = [
                        {
                            id: this.banner.id,
                            name: name,
                            tempName: name,
                            url: this.banner.image_id_locale,
                            path: this.banner.image_id_locale,
                            size: 0,
                            type: `image/${type}`,
                        }
                    ]
                    this.idImages = idImages
                }

                this.form.priority = this.banner.priority
                this.form.link = this.banner.link
                this.form.platform = this.banner.platform
            } else {
                this.resetModal();
            }
        },

        resetModal() {
            this.form = {
                priority: 0,
            };
            this.images = []
            this.idImages = []
        },

        async handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            await this.handleSubmit()
        },

        async handleSubmit() {
            if (this.images.length) {
                const image = {
                    path: this.images[0].path,
                    name: this.images[0].file && this.images[0].file.name || this.images[0].tempName,
                    updated: this.images[0].updated,
                }
                let idImage = null;
                if (this.idImages.length) {
                    idImage = {
                        path: this.idImages[0].path,
                        name: this.idImages[0].file && this.idImages[0].file.name || this.idImages[0].tempName,
                        updated: this.idImages[0].updated,
                    }
                }
                if (this.banner) {
                    const res = await bannerService.updateBanner(this.banner.id, {
                        priority: this.form.priority,
                        platform: this.form.platform,
                        image,
                        image_id_locale: idImage,
                        link: this.form.link,
                    })
                    if (res) {
                        this.$emit('on-close', true)
                    }
                } else {
                    const res = await bannerService.createBanner({
                        priority: this.form.priority,
                        platform: this.form.platform,
                        image,
                        image_id_locale: idImage,
                        link: this.form.link,
                    })
                    if (res) {
                        this.$emit('on-close', true)
                    }
                }
            }
        },

        onBeforeDeleteImage(fileRecord) {
            const index = this.images.indexOf(fileRecord)
            if (index !== -1) {
                this.images.splice(index, 1)
            }
        },

        onBeforeDeleteIdImage(fileRecord) {
            const index = this.idImages.indexOf(fileRecord)
            if (index !== -1) {
                this.idImages.splice(index, 1)
            }
        },

        async filesSelectedImage(fileRecords) {
            if (this.images.length) {
                for (let i = 0; i < this.images.length; i++) {
                    if (!this.images[i].id && !this.images[i].path) {
                        this.images[i].path = await getBase64(this.images[i].file)
                        this.images[i].updated = true
                    }
                }
            }
        },

        async filesSelectedIdImage(fileRecords) {
            if (this.idImages.length) {
                for (let i = 0; i < this.idImages.length; i++) {
                    if (!this.idImages[i].id && !this.idImages[i].path) {
                        this.idImages[i].path = await getBase64(this.idImages[i].file)
                        this.idImages[i].updated = true
                    }
                }
            }
        },
    },

    computed: {
        title() {
            return this.banner != null ? 'Update banner' : 'Add new banner';
        },
    },
}
</script>

<style lang="scss" scoped>

</style>