import store from '../store/store';

const roles = {
  Admin: 'ADMIN',
  User: 'USER'
};

export default roles;

const isUser = () => {
  return store.state.userProfile && store.state.userProfile.role === roles.User;
}

const isAdmin = () => {
  return store.state.userProfile && store.state.userProfile.role === roles.Admin;
}

export {
  isUser,
  isAdmin
};
