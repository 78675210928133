<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="pb-2">
                    <b-button size="sm" id="show-btn" @click="showAddModal()">
                        <b-icon icon="plus-lg"></b-icon>
                        Add Banner
                    </b-button>
                </div>
                <div slot="raw-content" class="table-responsive">
                    <paper-table :data="banners.data" :columns="tableColumns" :imageColumns="imageColumns"
                        :show-edit-action="true" :show-delete-action="true" @onModalEdit="onModalEdit" @onModalDelete="onModalDelete">
                        <template #theadSearch>
                            <thead class="search">
                                <th width="3%"></th>
                                <th width="50%"></th>
                                <th width="20%"></th>
                                <th width="10%"></th>
                                <th width="17%"></th>
                            </thead>
                        </template>
                    </paper-table>
                    <b-pagination v-if="banners.total" align="right" v-model="banners.current_page"
                        :total-rows="banners.total" :per-page="banners.per_page" @change="onChangePage"
                        aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <modal-add-banner :show="showModalAdd" @on-close="hideModalAdd" :banner="banner" />
        <b-modal v-model="modalDelete.show" header-bg-variant="light" header-text-variant="dark">
            <template #modal-header>
                <h5 class="modal-header-title mb-0">Delete confirm</h5>
                <button id="btn_closeModalDeleteBanner" type="button" class="close font-weight-bold" aria-label="Close"
                    @click="modalDelete.show = false">×</button>
            </template>
            <p>Are you sure you want to delete this banner?
            </p>
            <template #modal-footer>
                <b-button variant="danger" size="sm" @click="onDeleteConfig">
                    Delete
                </b-button>
                <b-button variant="primary" size="sm" @click="modalDelete.show = false">
                    Close
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { PaperTable } from "../../components";
import ModalBroadcastDetail from "./modals/ModalBroadcastDetail.vue"
import ModalAddBanner from "./modals/ModalAddBanner.vue"
import bannerService from '../../services/admin/banner.service'
import { urlImage } from "../../helpers/common"

const tableColumns = ["Id", "Image", "Priority", "Display"]

export default {
    components: {
        PaperTable,
        ModalBroadcastDetail,
        ModalAddBanner,
    },
    data() {
        return {
            banners: {},
            tableColumns: [...tableColumns],
            imageColumns: [1],
            banner: null,
            showModalAdd: false,
            modalDelete: {
                show: false,
            },
        };
    },
    async mounted() {
        await this.getBanners(1);
    },
    methods: {

        async getBanners(page) {
            const filters = {
                page,
            };
            const result = await bannerService.getList(filters);
            if (result && result.data) {
                this.banners = result;
                this.banners.data = this.banners.data.map(e => {
                    e.image = urlImage(e)
                    e.image_id_locale = urlImage({image: e.image_id_locale})
                    e.display = e.platform ? e.platform : 'web & app'
                    return e
                })
            }
        },

        async onChangePage(page) {
            await this.getBanners(page);
        },

        onModalEdit(data) {
            if (data) {
                this.banner = this.banners.data.find((e) => e.id === data);
                this.showModalAdd = true;
            }
        },

        onModalDelete({ id }) {
            this.modalDelete = {
                show: true,
                id,
            }
        },

        async onDeleteConfig() {
            const res = await bannerService.deleteBanner(this.modalDelete.id)
            if (res) {
                this.modalDelete = {
                    show: false,
                }
                this.getBanners(1)
            }
        },

        showAddModal() {
            this.showModalAdd = true;
        },

        hideModalAdd(success) {
            this.showModalAdd = false;
            this.banner = null;
            if (success) {
                this.getBanners(1)
            }
        },
    }
};
</script>
<style scoped>

</style>
  