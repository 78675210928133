<template>
    <div class="popup" v-if="isOpen" style="z-index: 1000001;">
        <img src="@/assets/img/maintenance/top-bg.svg" class="fixed-top d-none d-md-block" alt="" />
        <img src="@/assets/img/maintenance/top-bg-mobile.svg" class="bg-top-mobile d-block d-md-none" alt="" />
        <img src="@/assets/img/logo_with_text.png" class="logo mx-auto d-block d-md-none" alt="" />
        <img src="@/assets/img/logo_with_text.png" class="logo left d-none d-md-block" alt="" />
        <img src="@/assets/img/maintenance/bottom-bg.svg" class="fixed-bottom d-none d-md-block w-100" alt="" />
        <div class="d-flex flex-column flex-lg-row justify-content-center content align-items-center">
            <div
                class="col-10 col-md-9 col-lg-5 order-2 order-lg-1 d-flex flex-column align-items-center align-items-lg-start">
                <p class="font-52 font-weight-bold text-center text-lg-left">{{
        $t('MAINTENANCE.THIS_SITE_UNDER_MAINTENANCE') }}</p>
                <p class="font-28 mt-4 text-center text-lg-left">{{ message }}</p>
            </div>
            <img src="@/assets/img/maintenance/maintenance.svg" class="col-10 col-md-7 col-lg-5 order-1 order-lg-2"
                alt="" />
        </div>
    </div>

</template>
<script>

import axios from 'axios';
import i18n from "../i18n"

export default {

    data() {
        return {
            maintenance: {},
        }
    },

    watch: {
        maintenance(value) {
            if (value.mode === 'ON') {
                document.body.classList.add("modal-open")
            } else {
                document.body.classList.remove("modal-open")
            }
        }
    },

    mounted() {
        this.checkMaintenance()
    },

    methods: {
        async checkMaintenance() {
            try {
                const url = `${process.env.VUE_APP_IMG_HOST}/configs/maintenance.json?v=${new Date().getTime()}`
                const res = await axios.get(url)
                if (res.data) {
                    this.maintenance = res.data
                }
                setTimeout(() => {
                    this.checkMaintenance()
                }, this.isOpen ? 10000 : 60000)
            } catch (e) { }
        },
    },

    computed: {
        message() {
            const locale = i18n.global.locale.value
            const defaultMessage = this.$t('MAINTENANCE.WE_WILL_BACK_LATER')
            if (locale === 'id') {
                return this.maintenance.message_id || defaultMessage
            }
            return this.maintenance.message_en || defaultMessage
        },

        isOpen() {
            return this.maintenance.mode === 'ON'
        },
    },
}
</script>

<style lang="scss" scoped>
.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #E7F6F6;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-color);

    .content {
        z-index: 1031;
    }

    .bg-top-mobile {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        right: 0;
    }

    .logo {
        width: 150px;
        z-index: 1031;
        position: absolute;
        top: 20px;

        &.left {
            left: 80px;
        }
    }
}
</style>