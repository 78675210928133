<template>
  <div id="app" :class="routeClassName" class="sticky-wrapper" style="z-index: 1;">
    <metainfo>
      <template v-slot:title="{ content }">{{ content ? `${content}` : `GORO` }}</template>
    </metainfo>
    <loading :active="isLoading" :can-cancel="false" :is-full-page="true"
             loader="spinner" color="#343a40" :z-index="11000"></loading>
    <page-loader :is-loading="!isPageLoaded" @loading-complete="onPageLoaded"></page-loader>
    <notifications position="bottom center" class="notifications-group" />
    <div class="page-container" :id="pageContainerId">
      <template v-show="isPageLoaded">
        <web-header v-if="!isMobile" ref="webHeader" v-show="$route.meta.header !== 'hidden'"/>
        <router-view :style="{ paddingTop: isMobile ? '0px' : contentPaddingTop }"></router-view>
        <web-footer v-if="!isMobile" v-show="$route.meta.footer !== 'hidden'" :style="{ 'padding-bottom': paddingBottom }"/>
      </template>
    </div>

    <WhatsAppWidget v-if="shouldLoadKommoScript" />

    <virtual-balance-bubble v-show="$route.meta.virtual_balance_bubble !== 'hidden'" />

    <PopupSecurityPin :shouldCheckPin="true"></PopupSecurityPin>

    <PopupVerify2FA />

    <KommoScriptLoader v-if="shouldLoadKommoScript" />

    <PopupMaintenance v-if="showMaintenance" />

    <PurchaseProofNotification />

    <OnboardingQuiz />
  </div>
</template>

<script>
import WebHeader from "./layout/ui/Header.vue"
import WebFooter from "./layout/ui/Footer.vue"
import WhatsAppWidget from "./components/WhatsAppWidget"
import VirtualBalanceBubble from "./components/VirtualBalanceBubble.vue"
import { useMeta } from "vue-meta"
import PageLoader from "@/components/PageLoader.vue"
import PopupSecurityPin from "@/components/PopupSecurityPin.vue"
import PopupVerify2FA from "@/components/PopupVerify2FA.vue"
import PopupMaintenance from "@/components/PopupMaintenance.vue"
import { isAdmin } from "./constants/roles"
import PurchaseProofNotification from './components/Notifications/PurchaseProofNotification.vue'
import OnboardingQuiz from './components/Quizzes/OnboardingQuiz.vue'

export default {
  components: {
    WebHeader,
    WebFooter,
    WhatsAppWidget,
    VirtualBalanceBubble,
    PageLoader,
    PopupSecurityPin,
    PopupVerify2FA,
    PopupMaintenance,
    PurchaseProofNotification,
    OnboardingQuiz,
  },
  data() {
    return {
      faviconPath: require("@/assets/img/logo.png"),
      title: "GORO",
      isPageLoaded: false,
      contentPaddingTop: '0px',
      pageContainerId: ''
    }
  },
  async mounted () {
    this.handleWindowResize();
    window.addEventListener("resize", this.handleWindowResize)
    document.onreadystatechange = () => {
      if (document.readyState === "complete") {
        this.onPageLoaded()
      }
    }
    await store.dispatch("refreshSanctumCsrfCookie")
    await store.dispatch("getGeoLocation")
    await store.dispatch("getAdminPermissions")
    await store.dispatch("refreshConfig")
    await store.dispatch("refreshUserProfile")
    await store.dispatch("getUnreadNotificationCount")
    await store.dispatch("getInitialBalance")
    await store.dispatch("getExchangeRates")
    await store.dispatch("getPendingTaskCount")

    this.pageContainerId = this.$route.meta.id
  },

  beforeDestroy () {
    window.removeEventListener("resize", this.handleWindowResize)
  },

  methods: {
    onPageLoaded () {
      this.isPageLoaded = true
    },
    handleWindowResize () {
      if (this.$refs.webHeader) {
        if (this.routeClassName !== "hide-header-footer") {
          this.contentPaddingTop = `${this.$refs.webHeader.$el.clientHeight - 5}px`
        } else {
          this.contentPaddingTop = "80px"
        }
      }
    },
  },
  computed: {
    shouldLoadKommoScript () {
      return !window.location.pathname.includes("landing-en")
        && !window.location.pathname.includes("landing-wa")
        && !window.location.pathname.includes("landing-wa-2")
        && !window.location.pathname.includes("login-admin")
        && !window.location.pathname.includes("admin")
        && !window.location.pathname.includes("property-image")
    },
    isLoading() {
      return store.getters.isLoading;
    },
    routeClassName() {
      if (this.$route.path && (this.$route.path.includes('admin')
        || this.$route.path.includes('account')
        || this.$route.path.includes('buy-success')
        || this.$route.path.includes('sharing-assets-overview')
        || this.$route.path.includes('get-browser-session-id')
        || this.$route.path.includes('property-image'))) {
        return 'hide-header-footer'
      } else {
        return 'app';
      }

    },
    paddingBottom() {
      return store.getters.userProfile ? '10px' : '150px'
    },

    isMobile() {
      return this.$route.query.mobile
    },

    showMaintenance() {
      return !isAdmin() && this.$route.path && !this.$route.path.includes('admin')
    }
  },
  watch: {
    $route: {
      handler(to, from) {
        this.pageContainerId = to.meta.id
        store.dispatch('getUnreadNotificationCount');
      },
      deep: true
    }
  },
  setup() {
    const title = 'GORO'
    const faviconPath = require("@/assets/img/logo.png")
    useMeta({
      title: title,
      meta: [
        { property: 'og:title', content: title },
        { property: 'og:site_name', content: title },
      ],
      link: [
        { rel: 'icon', href: faviconPath }
      ]
    })
  }
};
</script>

<style lang="scss">
.notifications-group {
  bottom: 15px !important;

  span {
    .vue-notification-template.vue-notification.success {
      font-size: 14px;
      text-align: center;
      color: white;
      border: 3px solid var(--primary-color) !important;
      border-radius: 30px;
      background-color: var(--primary-color);
      box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
    }

    .vue-notification-template.vue-notification.error {
      font-size: 14px;
      text-align: center;
      color: #ffffff;
      border: #A82116 !important;
      border-radius: 30px;
      background-color: #A82116;
      box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
    }

    .vue-notification-template.vue-notification.warn {
      font-size: 14px;
      text-align: center;
      color: white;
      border: 3px solid #F5C275 !important;
      border-radius: 30px;
      background-color: #F5C275;
      box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
