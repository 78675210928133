<template>
    <div class="maintenance">
        <b-row align-h="center">
            <b-col cols="12" lg="6">
                <form class="col-12 d-flex flex-column align-items-start" @submit.stop.prevent="handleSubmit">
                    <div class="d-flex flex-row justify-content-center w-100">
                        <b-form-radio class="mr-5" v-model="maintenance.mode" name="on" value="ON">ON</b-form-radio>
                        <b-form-radio v-model="maintenance.mode" name="off" value="OFF">OFF</b-form-radio>
                    </div>

                    <p class="mt-3">Message EN:</p>
                    <b-form-textarea v-model="maintenance.message_en" type="text" id="input-en"
                        placeholder="Maintenance message for en locale" rows="5" max-rows="10" class="mt-3">
                    </b-form-textarea>

                    <p class="mt-3">Message ID:</p>
                    <b-form-textarea v-model="maintenance.message_id" type="text" id="input-id"
                        placeholder="Maintenance message for id locale" rows="5" max-rows="10" class="mt-3">
                    </b-form-textarea>

                    <div class="text-center w-100">
                        <b-button class="mt-5" type="submit" variant="primary">Update</b-button>
                    </div>
                </form>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import maintenanceService from "../../services/admin/maintenance.service"

export default {
    data() {
        return {
            maintenance: {
                mode: "OFF",
                message_en: "",
                message_id: "",
            },
        }
    },

    mounted() {
        this.getMaintenance()
    },

    methods: {
        async getMaintenance() {
            const res = await maintenanceService.get()
            if (res.data) {
                this.maintenance = res.data
            }
        },
        async handleSubmit() {
            const res = await maintenanceService.update(this.maintenance)
            if (res.data) {
                this.maintenance = res.data
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.maintenance {
    width: 100%;
    height: 80vh;
}
</style>