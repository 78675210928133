<template>
    <b-modal v-model="showModal" id="full-screen-modal" size="huge" header-bg-variant="dark" header-text-variant="light">
        <template #modal-header>
            <h5 class="modal-header-title">{{ title }}</h5>
            <button type="button" class="close font-weight-bold" aria-label="Close"
                @click="onClose({ show: false })">×</button>
        </template>
        <div class="container">
            <b-form class="form-content">
                <b-row class="mb-4">
                    <b-col cols="2">
                        <label>Id:</label>
                        <b-form-input :value="emailObject.id" type="text" placeholder="Id" :disabled="true">
                        </b-form-input>
                    </b-col>
                    <b-col cols="5">
                        <label>Campaign Title:</label>
                        <b-form-input :value="emailObject.title" type="text" placeholder="" :disabled="true">
                        </b-form-input>
                    </b-col>
                    <b-col cols="5">
                        <label>Alias:</label>
                        <b-form-input :value="emailObject.alias" type="text" placeholder="" :disabled="true">
                        </b-form-input>
                    </b-col>
                </b-row>
                <b-row class="mb-4">
                    <b-col cols="12">
                        <label>To:</label>
                        <b-form-textarea :value="emailObject.to" type="text" placeholder="" :disabled="true" rows="5"
                            max-rows="10">
                        </b-form-textarea>
                    </b-col>
                </b-row>
                <b-row class="mb-4">
                    <b-col cols="12">
                        <label>CC:</label>
                        <b-form-textarea :value="emailObject.cc" type="text" placeholder="" :disabled="true" rows="5"
                            max-rows="10">
                        </b-form-textarea>
                    </b-col>
                </b-row>
                <b-row class="mb-4">
                    <b-col cols="12">
                        <label>BCC:</label>
                        <b-form-textarea :value="emailObject.bcc" type="text" placeholder="" :disabled="true" rows="5"
                            max-rows="10">
                        </b-form-textarea>
                    </b-col>
                </b-row>
                <b-row class="mb-4">
                    <b-col cols="4">
                        <label>Tag:</label>
                        <b-form-input :value="emailObject.tag" type="text" placeholder="" :disabled="true">
                        </b-form-input>
                    </b-col>
                    <b-col cols="4">
                        <label>Created Time:</label>
                        <b-form-input :value="time" type="text" placeholder="" :disabled="true">
                        </b-form-input>
                    </b-col>
                    <b-col cols="4">
                        <label>Schedule On:</label>
                        <b-form-input :value="scheduleOn" type="text" placeholder="" :disabled="true">
                        </b-form-input>
                    </b-col>
                </b-row>
                <b-row class="mb-4">
                    <b-col cols="3">
                        <label>Error:</label>
                        <b-form-input :value="emailObject.error" type="text" placeholder="" :disabled="true">
                        </b-form-input>
                    </b-col>
                    <b-col cols="3">
                        <label>Retry:</label>
                        <b-form-input :value="emailObject.retry_count" type="text" placeholder="" :disabled="true">
                        </b-form-input>
                    </b-col>
                    <b-col cols="3">
                        <label>Status:</label>
                        <b-form-input :value="emailObject.status" type="text" placeholder="" :disabled="true">
                        </b-form-input>
                    </b-col>
                    <b-col cols="3">
                        <label>Status Updated At:</label>
                        <b-form-input :value="updatedAt" type="text" placeholder="" :disabled="true">
                        </b-form-input>
                    </b-col>
                </b-row>
                <b-row class="mb-4">
                    <b-col cols="12">
                        <label>Model Data:</label>
                        <b-form-textarea :value="modalData" type="text" placeholder="" :disabled="true" rows="5"
                            max-rows="10">
                        </b-form-textarea>
                    </b-col>
                </b-row>
            </b-form>
        </div>
        <template #modal-footer>
            <b-button size="sm" variant="primary" @click="onClose({ show: false })">
                OK
            </b-button>
        </template>
    </b-modal>
</template>

<script>

import moment from 'moment';

export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        email: {
            type: Object,
            default() {
                return {}
            },
        },
    },

    data() {
        return {
            emailObject: this.email,
            showModal: false,
        }
    },

    watch: {
        email(value) {
            this.emailObject = value
        },
        show(value) {
            this.showModal = value
        },
    },

    methods: {
        onReset() {
            this.emailObject = {}
        },

        onClose: function (v) {
            this.onReset()
            this.$emit("onClose", v)
        },
    },

    computed: {
        title() {
            return "Broadcast Detail"
        },

        time() {
            return moment(this.emailObject.created_at).format('DD/MM/YYYY HH:mm');
        },

        updatedAt() {
            return moment(this.emailObject.updated_at).format('DD/MM/YYYY HH:mm');
        },

        scheduleOn() {
            return moment(this.emailObject.schedule_time * 1000).format('DD/MM/YYYY HH:mm');
        },

        modalData() {
            if (!this.emailObject.model_data) {
                return ''
            }
            return JSON.stringify(this.emailObject.model_data, null, '\t')
        },
    },
}
</script>

<style scoped>
:deep() #full-screen-modal {
    padding: 0 !important;
    background: #f4f3ef;
}

:deep() #full-screen-modal .modal-dialog {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    margin: 0;
    background: #f4f3ef;
}

:deep() #full-screen-modal .modal-content {
    height: 100%;
    border: none;
    border-radius: 0;
    background: #f4f3ef;
}

:deep() #full-screen-modal .modal-header {
    border-radius: 0;
}

.modal-header-title {
    margin: 0;
    font-weight: bold;
}

.close {
    color: #ffffff;
    opacity: .8;
    padding-bottom: 20px !important;
    /*!* opacity: .5; */
}

.images-picker {
    width: 60%;
}

.document-content {
    margin: 20px 0;
    padding-bottom: 20px;
}

.document-content li {
    list-style: none;
    color: #3a283d;
    margin: 3px 0;
    font-size: 16px;
}

.form-content label {
    font-weight: bold;
    font-size: 16px;
}

.capitalize:first-letter {
    text-transform: uppercase;
}

/*:deep() .mapboxgl-canvas {*/
/*  width: 100% !important;*/
/*  height: 100% !important;*/
/*}*/
</style>
