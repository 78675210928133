import { createRouter, createWebHistory } from "vue-router"
import i18n from "../i18n"
import admin from "./admin"
import { user, userForeigner } from "./user"
import { account, accountForeigner } from "./account"
import { isInactive } from "../constants/userStatus"
import { FOREIGNER, INDO, PAYMENT_METHOD } from "../constants/constants"
import store from "../store/store"

// configure router
const routes = [...admin, ...user, ...userForeigner, ...account, ...accountForeigner]

const router = createRouter({
  // mode: 'history',
  history: createWebHistory(),
  routes,
  scrollBehavior () {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" })
  }
})

const isRoutePath = (to, routePath) => {
  const foreignerRoutePath = `${FOREIGNER.ROUTE_PATH_PREFIX}${routePath}`
  return to.path === routePath || to.path === foreignerRoutePath
}

const isRouteName = (to, routeName) => {
  const foreignerRouteName = `${routeName}${FOREIGNER.ROUTE_NAME_SUFFIX}`
  return to.name === routeName || to.path === foreignerRouteName
}

const isAdminRoute = (route) => (route.path && route.path.includes("admin")) || (route.name && route.name.includes("admin"))

const isForeignerRoute = (route) =>
  (route.path && route.path.startsWith(FOREIGNER.ROUTE_PATH_PREFIX))
  || (route.name && route.name.endsWith(FOREIGNER.ROUTE_NAME_SUFFIX))

const updateToForeignerRoute = (route) => {
  if (route.path && !route.path.startsWith(FOREIGNER.ROUTE_PATH_PREFIX)) {
    route.path = `${FOREIGNER.ROUTE_PATH_PREFIX}${route.path}`
  }
  if (route.name && !route.name.endsWith(FOREIGNER.ROUTE_NAME_SUFFIX)) {
    route.name = `${route.name}${FOREIGNER.ROUTE_NAME_SUFFIX}`
  }
  return route
}

const updateToNormalRoute = (route) => {
  if (route.path && route.path.startsWith(FOREIGNER.ROUTE_PATH_PREFIX)) {
    route.path = route.path.substring(FOREIGNER.ROUTE_PATH_PREFIX.length) || "/"
  }
  if (route.name && route.name.endsWith(FOREIGNER.ROUTE_NAME_SUFFIX)) {
    route.name = route.name.split(FOREIGNER.ROUTE_NAME_SUFFIX)[0] || "home"
  }
  return route
}

const nextByLocale = (to, from, next, routeParams) => {
  if (isAdminRoute(to)) {
    next(routeParams)
  } else {
    let useForeignerRoute = false
    let user = store.getters.userProfile
    if (user && user.phone) {
      useForeignerRoute = !user.phone.startsWith(INDO.CALLING_CODE)
    } else if (store.getters.switchToNewLocation) {
      let browserLanguage = window.navigator.language
      let isBrowserLanguageIndo = (!browserLanguage || browserLanguage.toLowerCase() === "id" || browserLanguage.toLowerCase().startsWith("id-"))
      let geoLocation = store.getters.geoLocation
      if (!isBrowserLanguageIndo
        || (geoLocation.country_code !== INDO.COUNTRY_CODE && geoLocation.iso_country_code !== INDO.ISO_COUNTRY_CODE)) {
        useForeignerRoute = true
      }
    } else if (isForeignerRoute(to) || isForeignerRoute(from)) {
      useForeignerRoute = true
    }

    if (routeParams) {
      if (useForeignerRoute) {
        routeParams = updateToForeignerRoute(routeParams)
      } else {
        routeParams = updateToNormalRoute(routeParams)
      }
      return next(routeParams)
    } else {
      if (useForeignerRoute) {
        if (!isForeignerRoute(to)) {
          return next(updateToForeignerRoute({ path: to.path, name: to.name, query: to.query, params: to.params }))
        }
      } else {
        if (isForeignerRoute(to)) {
          return next(updateToNormalRoute({ path: to.path, name: to.name, query: to.query, params: to.params }))
        }
      }
    }
    next(routeParams)
  }
}

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  // window.document.title = to.meta && to.meta.title ? to.meta.title : 'Goro';
  to.meta.id = to.name
  const matched = (to.meta.authorize) || to.matched.find(r => r.meta.authorize)?.meta?.authorize
  const authorize = matched || null
  const { userProfile } = store.getters
  const storageAuth = localStorage.getItem("Authorization")

  if (isRoutePath(to, "/refer")) {
    if (storageAuth) {
      if (!isInactive()) {
        return nextByLocale(to, from, next, {path: "/"})
      } else {
        return nextByLocale(to, from, next, {name: "account", query: to.query})
      }
    } else {
      return nextByLocale(to, from, next, {name: "register", query: to.query})
    }
  }
  if (to.path.match(/\/invite/)) {
    let inviteCode = null;
    const pathSegments = to.path.split('/');
    const inviteIndex = pathSegments.indexOf('invite');
    if (inviteIndex !== -1 && pathSegments.length > inviteIndex + 1 && pathSegments[inviteIndex + 1]) {
      inviteCode = pathSegments[inviteIndex + 1];
    }
    // Clone the existing query parameters object
    const updatedQuery = {...to.query, code: inviteCode};
    if (storageAuth) {
      if (!isInactive()) {
        return nextByLocale(to, from, next, {path: "/"})
      } else {
        return nextByLocale(to, from, next, {name: "account", query: {...updatedQuery}})
      }
    } else {
      return nextByLocale(to, from, next, {name: "register", query: {...updatedQuery}})
    }
  }

  if (authorize) {
    if (!userProfile || !storageAuth) {
      // not logged in so redirect to login page with the return url
      if (to.name.startsWith("admin")) {
        return nextByLocale(to, from, next, { name: "loginAdmin", query: { redirect: to.path } })
      } else if (isRouteName(to, "pin") && to.query.token) {
        return nextByLocale(to, from, next, {
          name: "login",
          query: { redirect: `${to.path}?token=${to.query.token}` }
        })
      } else if (isRouteName(to, "confirmBankAccount") && to.query.code) {
        return nextByLocale(to, from, next, {
          name: "login",
          query: {redirect: `${to.path}?code=${to.query.code}`}
        })
      } else {
        return nextByLocale(to, from, next, {name: "login", query: {redirect: to.path}})
      }
    }
    // check if route is restricted by role
    if (authorize.length && !authorize.includes(userProfile.role)) {
      // role not authorised so redirect to home page
      return nextByLocale(to, from, next, { path: "/" })
    }

    // Check if user added bank account or not
    if (isRouteName(to, "createWithdrawal") && (userProfile.payment_method !== PAYMENT_METHOD.STRIPE && !userProfile.bank_account)) {
      return nextByLocale(to, from, next, { name: "withdrawals", query: { action: "add" } })
    }
  } else {
    if ((isRouteName(to, "login") || isRouteName(to, "register")
      || isRouteName(to, "forgotPassword") || isRouteName(to, "resetPassword"))
      && store.getters.userProfile && storageAuth) {
      return nextByLocale(to, from, next, { name: "assetsOverview" })
    } else if (isRoutePath(to, "/reset-password")) {
      const queryParams = to.query
      if (!queryParams.email || !queryParams.token) {
        nextByLocale(to, from, next, { path: "/" }) // Redirect to home
      }
    }
  }

  nextByLocale(to, from, next)
})

router.afterEach((to, from) => {
  //check to update locale
  let locale = i18n.global.locale.value
  if (to.path.startsWith(FOREIGNER.ROUTE_PATH_PREFIX)) {
    locale = FOREIGNER.LOCALE
  } else {
    let user = store.getters.userProfile
    if (user && user.phone && user.phone.startsWith(INDO.CALLING_CODE) && user.preferred_locale) {
      locale = user.preferred_locale
    } else {
      const preferredLocale = localStorage.getItem("preferred_locale")
      if (preferredLocale) {
        locale = preferredLocale
      } else {
        let browserLanguage = window.navigator.language
        if (i18n.global.availableLocales.includes(browserLanguage)) {
          locale = browserLanguage
        }
      }
    }
  }
  if (to.query.lang && i18n.global.availableLocales.includes(to.query.lang)) {
    locale = to.query.lang
  }
  if (i18n.global.locale.value !== locale) {
    i18n.global.locale.value = locale
  }
})

export default router
