<template>
  <div v-if="showNotification" class="proof-notification-container">
    <transition name="fade" @after-leave="onAfterLeave">
      <div v-if="activeNotification" class="notification">
        <div v-if="activeNotification.type == 'ITEM_PURCHASE'" class="notification-wrapper">
          <img class="img" src="@/assets/img/logo_goro.jpg">
          <div class="item-content">
            <p class="message">
              <strong>{{ activeNotification.user.username }}</strong> {{ $t('PROOF_NOTIFICATION.MESSAGE', {
                token: activeNotification.transaction.num_of_tokens,
                token_label: (activeNotification.transaction.num_of_tokens > 1 ? $t('common.TOKENS') : $t('common.TOKEN')).toLowerCase().trim(),
                }) }} <span class="link" @click="openPropertyDetails(activeNotification.to_property)">{{ activeNotification.to_property.name }}</span>
            </p>
          </div>
        </div>
        <div v-if="activeNotification.type == 'TOTAL_PURCHASE' || activeNotification.type == 'TOTAL_ALL_PURCHASE'" class="notification-wrapper total-wrapper">
          <img class="img" src="@/assets/img/default_avatar_purchase.png">
          <div class="item-content">
            <p v-if="activeNotification.type == 'TOTAL_ALL_PURCHASE'" class="message total">
              <strong>{{ activeNotification.total }}</strong> {{ $t('PROOF_NOTIFICATION.MESSAGE_TOTAL_ALL_PURCHASE', {
                total: activeNotification.total,
                hour: activeNotification.hour,
                }) }}
            </p>
            <p v-if="activeNotification.type == 'TOTAL_PURCHASE'" class="message total">
              <strong>{{ activeNotification.total }}</strong> {{ $t('PROOF_NOTIFICATION.MESSAGE_TOTAL_PURCHASE', {
                total: activeNotification.total,
                hour: activeNotification.hour,
                }) }}
            </p>
          </div>
        </div>
        <span v-if="activeNotification.type == 'ITEM_PURCHASE'" class="time-human">{{ activeNotification.time_human }}</span>
        <button id="btn_closeProofNotification" v-if="showClose" @click="closeNotification" class="close-notification">
          <img src="@/assets/img/icons/close.png">
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import activitiesService from "../../services/activities.service"
import { urlImage } from '../../helpers/common'

export default {
  data() {
    return {
      firstDelay: 2500,
      nextShowDelay: 1500,
      duration: 5000,
      notifications: [],
      activeNotification: null,
      notificationIndex: 0,
      notificationTimeout: null,
      showNotification: false,
      propertyUuid: null,
      loop: false,
      showClose: false,
      routesNameAllowedShowNotification: [
        'propertyDetail',
        'propertyDetailForeigner',
        'accountPropertyDetail',
        'accountPropertyDetailForeigner',
      ]
    }
  },
  methods: {
    resetLoop() {
      if (this.loop) {
        if (this.notificationIndex >= this.notifications.length) {
          this.notificationIndex = 0
        }
      }
    },
    showNextNotification() {
      this.resetLoop()
      if (this.notificationIndex < this.notifications.length) {
        this.activeNotification = this.notifications[this.notificationIndex]
        this.notificationTimeout = setTimeout(() => {
          this.activeNotification = null
        }, this.duration) // Show notification for X seconds
      }
    },
    closeNotification() {
      clearTimeout(this.notificationTimeout)
      this.activeNotification = null
      this.showNotification = false
      this.notifications = []
      this.propertyUuid = null
    },
    onAfterLeave() {
      this.notificationIndex++
      setTimeout(() => {
        this.showNextNotification()
      }, this.nextShowDelay) // X seconds delay before showing the next notification
    },
    async startNotifications() {
      if (this.propertyUuid) {
        const res = await activitiesService.purchaseByProperyUuid(this.propertyUuid)
        if (res && res.data) {
          this.notifications = res.data
          setTimeout(() => {
            this.showNextNotification()
          }, this.firstDelay) // X seconds delay after page load to show the first notification
        }
      }
    },
    avatarUrl(avatar_url = null) {
      if (avatar_url) {
        return urlImage({ image: avatar_url })
      }
      return ''
		},
    async openPropertyDetails(property) {
        const route = this.$router.resolve({ name: 'propertyDetail', params: { uuid: property.uuid } });
        window.open(route.href, '_blank');
    },
  },
  watch: {
    async $route(to) {
      this.closeNotification()
      
      if (this.routesNameAllowedShowNotification.includes(to.name)) {
        this.notificationIndex = 0
        this.showNotification = true
        this.propertyUuid = to.params.uuid
        await this.startNotifications()
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.proof-notification-container {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  .notification {
    background-color: transparent;
    position: relative;
    padding: 20px;
    .notification-wrapper{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: rgba(0, 145, 142, 0.95);
      color: #fff !important;
      padding: 22px;
      border-radius: 15px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      width: 400px;
      min-height: 81px;
      @media screen and (max-width: 400px) {
        width: 350px;
        min-height: 81px;
      }
      .item-content{
        .message{
          color: #fff !important;
          font-family: "AcuminVariableConcept";
          line-height: 21px;
          font-size: 16px;
          padding: 5px 0;
          @media screen and (max-width: 400px) {
            font-size: 14px;
          }
          strong{
            color: #fff !important;
          }
          &.total{
            font-size: 16px;
          }
          .link{
            color: #fff !important;
            font-family: "AcuminVariableConcept";
            line-height: 21px;
            font-size: 16px;
            padding: 5px 0;
            text-decoration: underline;
            text-underline-offset: 4px;
            cursor: pointer;
            &:hover{
              color: #f38300 !important;
            }
          }
        }
      }
      img.img{
        // border-radius: 100%;
        min-width: 42px;
        width: 42px;
        height: 42px;
        object-fit: cover;
        margin-right: 15px;
        border-radius: 100%;
        background-color: #fff;
        padding: 3px;
      }
      &.total-wrapper{
        justify-content: center;
        padding: 18px 16px !important;
        min-height: 74px;
        width: 340px;
        @media screen and (max-width: 400px) {
          width: 340px;
          min-height: 74px;
        }
        img.img{
          min-width: 38px;
          width: 38px;
          height: 38px;
          padding: 0;
          border-radius: 0 !important;
          background-color: transparent !important;
        }
      }
    }
    .time-human{
      font-family: "AcuminVariableConcept";
      font-size: 12px;
      line-height: 10.8px;
      position: absolute;
      position: absolute;
      top: 32px;
      right: 35px;
      color: #fff;
      @media screen and (max-width: 400px) {
        font-size: 11px;
      }
    }
    button.close-notification{
      width: 12px;
      height: 12px;
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 0;
      margin: 0;
      border: none;
      background: transparent;
      line-height: 0;
      img{
        width: 100%;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-enter-active {
  animation: fadeIn 0.5s forwards;
}

.fade-leave-active {
  animation: fadeOut 0.5s forwards;
}
</style>
