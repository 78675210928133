<template>
    <b-modal v-model="showModal" id="full-screen-modal" size="huge" header-bg-variant="dark" header-text-variant="light">
        <template #modal-header>
            <h5 class="modal-header-title">Virtual User Assets</h5>
            <button type="button" class="close font-weight-bold" aria-label="Close" @click="onClose()">×</button>
        </template>
        <div class="container">
            <b-row class="mb-3" align-v="center" v-if="user">
                <b-col class="font-22">
                    <p>Name: {{ user.name }}</p>
                    <p>Email: {{ user.email }}</p>
                    <p>Phone: {{ user.phone }}</p>
                    <p>Joined: {{ userJoinedAt }}</p>
                </b-col>
            </b-row>
            <b-row class="item" align-v="center" v-for="item in assets">
                <b-col cols="3">
                    <img style="width:100%" :src="getImageUrl(item.property.images[0])" alt="" />
                </b-col>
                <b-col class="ml-3" cols="8">
                    <p class="font-28 font-weight-bold">{{ item.property.name }}<b-icon class="link-icon"
                            @click="openPropertyDetails(item.property)" icon="arrow-up-right-square">
                        </b-icon></p>
                    <p>{{ item.property.metadata.address }}</p>
                    <p class="mt-1">Total tokens: {{ item.total_tokens }} tokens, Expired tokens:{{ item.expired_tokens }} </p>
                    <p>Price per token: IDR{{ getFormattedCurrency(item.property.price_per_token) }}</p>
                    <p>Average price per token: IDR{{ getFormattedCurrency(item.avg_price_per_token) }}</p>
                </b-col>
            </b-row>
            <p class="text-center font-18" v-if="!assets.length && !isLoading">There are no assets</p>
        </div>
        <template #modal-footer="{ ok, cancel, hide }">
            <b-button size="md" variant="primary" @click="onClose()">
                Close
            </b-button>
        </template>
    </b-modal>
</template>

<script>

import propertiesService from '../../../services/admin/properties.service';
import { formatCurrency, urlImage } from '../../../helpers/common';
import moment from 'moment';

export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        user: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            showModal: false,
            assets: [],
            assetsData: null,
        };
    },

    emits: ['on-close'],

    mounted() {

    },

    watch: {
        show(value) {
            this.showModal = value;
        },
        async user(value) {
            if (value) {
                this.assets = []
                this.getAssets(value.id);
            }
        }
    },

    methods: {

        async getAssets(id) {
            const res = await propertiesService.getVirtualOwnedByUser(id);
            if (res) {
                this.assetsData = res
                this.assets = res.assets;
            }
        },

        onClose: function () {
            this.$emit("on-close")
        },

        getImageUrl(image) {
            return urlImage(image);
        },

        getPercent(tokens) {
            return Math.round((tokens * 100 / this.property.total_tokens) * 10) / 10;
        },

        async openPropertyDetails(property) {
            const route = this.$router.resolve({ name: 'propertyDetail', params: { uuid: property.uuid } });
            window.open(route.href, '_blank');
        },

        getFormattedCurrency(value) {
            return formatCurrency(value)
        },
    },

    computed: {
        isLoading() {
            return this.$store.getters.isLoading;
        },

        userJoinedAt() {
            return moment(this.user.created_at).format('DD/MM/YYYY');
        },
    },
}
</script>

<style scoped>
:deep() #full-screen-modal {
    padding: 0 !important;
    background: #f4f3ef;
}

:deep() #full-screen-modal .modal-dialog {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    margin: 0;
    background: #f4f3ef;
}

:deep() #full-screen-modal .modal-content {
    height: 100%;
    border: none;
    border-radius: 0;
    background: #f4f3ef;
}

:deep() #full-screen-modal .modal-header {
    border-radius: 0;
}

.modal-header-title {
    margin: 0;
    font-weight: bold;
}

.close {
    color: #ffffff;
    opacity: .8;
    padding-bottom: 20px !important;
    /*!* opacity: .5; */
}

.item {
    background-color: white;
    padding: 20px;
}

.link-icon {
    width: 20px;
    height: 20px;
    margin-left: 15px;
    margin-bottom: 5px;
    cursor: pointer;
}
</style>