<template>
    <b-modal v-model="showModal" id="full-screen-modal" size="huge" header-bg-variant="dark" header-text-variant="light"
      :propertyId="propertyId" content-class="modal-body-full">
      <template #modal-header>
        <h5 class="modal-header-title">{{ title }}</h5>
        <button type="button" class="close font-weight-bold" aria-label="Close" @click="onClose({ show: false })">×</button>
      </template>
      <div class="container">
        <b-form class="form-content" @submit.stop.prevent="onSubmit">
          <b-row class="mb-4">
            <b-col :cols="property.status == 'promo' || property.status == 'presale' ? '12' : '6'">
              <b-row>
                <b-col>
                  <label>Property status:</label>
                  <b-form-select v-model="property.status" :state="validateState('status')" :options="statusList">
                  </b-form-select>
                </b-col>
                <b-col cols="8" v-if="property.status == 'promo' || property.status == 'presale'">
                  <label class="capitalize">{{ property.status }} ends at:</label>
                  <b-row v-if="property.status == 'promo'">
                    <b-col cols="8">
                      <b-form-datepicker v-model="picker.promo_date" class="mb-2"></b-form-datepicker>
                    </b-col>
                    <b-col cols="4">
                      <b-form-timepicker :hour12="false" v-model="picker.promo_time" locale="en"></b-form-timepicker>
                    </b-col>
                  </b-row>
                  <b-row v-if="property.status == 'presale'">
                    <b-col cols="8">
                      <b-form-datepicker v-model="picker.presale_date" class="mb-2"></b-form-datepicker>
                    </b-col>
                    <b-col cols="4">
                      <b-form-timepicker :hour12="false" v-model="picker.presale_time" locale="en"></b-form-timepicker>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-form-invalid-feedback>Status is a required field.</b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="6">
              <b-row>
                <b-col>
                  <label>Weight:</label>
                  <b-form-input v-model="property.weight" :state="validateState('weight')" type="number"
                    placeholder="Weight">
                  </b-form-input>
                </b-col>
                <b-col>
                  <label>Total tokens:</label>
                  <b-form-input v-model="property.total_tokens" :state="validateState('total_tokens')" type="number"
                    placeholder="Total tokens"></b-form-input>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col>
                  <label>Sold tokens:</label>
                  <b-form-input v-model="property.sold_tokens" :state="validateState('sold_tokens')" type="number"
                    placeholder="Sold tokens"></b-form-input>
                </b-col>
                <b-col>
                  <label>Display sold tokens:</label>
                  <b-form-input v-model="property.display_sold_tokens" :state="validateState('display_sold_tokens')"
                    type="number" placeholder="Display sold tokens"></b-form-input>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col>
              <b-row>
                <b-col>
                  <label>Price Per Token (IDR):</label>
                  <b-input-group>
                    <b-form-input v-model="property.price_per_token" :state="validateState('price_per_token')" type="number"
                      placeholder="Price per token (IDR)"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label>Minimum Purchase Token:</label>
                  <b-input-group>
                    <b-form-input v-model="property.min_purchase_token" :state="validateState('min_purchase_token')"
                      type="number" placeholder="Minimum Purchase Tokem"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <label>First Live On:</label><br>
                  <VueDatePicker v-model="property.first_live_on" format="yyyy-MM-dd" style="width: 100%;" :disabled-dates="notAfterToday" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <template #modal-footer>
        <b-button size="sm" variant="success" @click="onSubmit()">
          Save
        </b-button>
        <b-button size="sm" variant="primary" @click="onClose({ show: false })">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </template>
  
  <script>
  import { useVuelidate } from '@vuelidate/core'
  import { decimal, integer, maxLength, minLength, minValue, required } from '@vuelidate/validators'
  import moment from "moment"
  import propertiesService from "../../../services/properties.service"
  import { PROPERTY_STATUSES_LIST } from "../../../constants/constants"
  
  export default {
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      theme: {
        type: String,
        default: "light"
      },
      title: {
        type: String,
        default: ""
      },
      propertyId: {
        type: Number,
        default: null,
      }
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
      return {
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        showModal: false,
        map: {
          location: [],
          geo: true,
          searchForm: true,
          height: 500,
          color: "#66615b",
          initDelay: 1000,
        },
        picker: {
          promo_date: null,
          promo_time: null,
          presale_date: null,
          presale_time: null,
        },
        property: {
          id: null,
          status: "",
          weight: 0,
          total_tokens: 0,
          sold_tokens: 0,
          display_sold_tokens: 0,
          price_per_token: 0,
          min_purchase_token: 1,
          promo_ends_at: null,
          presale_ends_at: null,
          first_live_on: null,
        },
        statusList: PROPERTY_STATUSES_LIST,
      }
    },
    validations() {
      return {
        property: {
          status: {
            required
          },
          weight: {
            integer,
          },
          total_tokens: {
            integer,
          },
          sold_tokens: {
            integer,
          },
          display_sold_tokens: {
            integer,
          },
          price_per_token: {
            integer,
          },
          min_purchase_token: {
            integer,
            minValue: minValue(1),
          },
          promo_ends_at: {
            integer,
          },
          presale_ends_at: {
            integer,
          },
        },
      }
    },
    watch: {
      async propertyId(v) {
        if (v) {
          this.property = await propertiesService.getById(v)
        }
      },
      show(value) {
        this.showModal = value
      },
      "property.promo_ends_at"(value) {
        if (value) {
          const str = moment(value).format(this.dateFormat).split(" ")
          this.picker.promo_date = str[0]
          this.picker.promo_time = str[1]
        } else {
          this.picker.promo_date = null
          this.picker.promo_time = null
        }
      },
      "property.presale_ends_at"(value) {
        if (value) {
          const str = moment(value).format(this.dateFormat).split(" ")
          this.picker.presale_date = str[0]
          this.picker.presale_time = str[1]
        } else {
          this.picker.presale_date = null
          this.picker.presale_time = null
        }
      },
      "picker.promo_date"(value) {
        const time = this.getTime(`${value} ${this.picker.promo_time}`)
        if (time) {
          this.property.promo_ends_at = time
        }
      },
      "picker.promo_time"(value) {
        const time = this.getTime(`${this.picker.promo_date} ${value}`)
        if (time) {
          this.property.promo_ends_at = time
        }
      },
      "picker.presale_date"(value) {
        const time = this.getTime(`${value} ${this.picker.presale_time}`)
        if (time) {
          this.property.presale_ends_at = time
        }
      },
      "picker.presale_time"(value) {
        const time = this.getTime(`${this.picker.presale_date} ${value}`)
        if (time) {
          this.property.presale_ends_at = time
        }
      },
    },
    async mounted() {},
    methods: {
      onReset() {
        // Reset our form values
        this.property.id = null
        this.property.status = ""
        this.property.total_tokens = 0
        this.property.sold_tokens = 0
        this.property.display_sold_tokens = 0
        this.property.price_per_token = 0
        this.property.min_purchase_token = 1
        this.property.promo_ends_at = null
        this.property.presale_ends_at = null
        this.property.first_live_on = null
      },
      notAfterToday(date) {
        // const today = new Date().setHours(0, 0, 0, 0)
        return false //date > today
      },
      validateState(name) {
        const { $dirty, $error } = this.v$.property[name]
        return $dirty ? !$error : null
      },
      onClose: function (v) {
        this.onReset()
        this.$emit("onClose", v)
      },
      async onSubmit() {
        this.v$.$touch();
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) {
          window.scrollTo(0, 0)
          return;
        }
        const data = await propertiesService.saveStatusAndToken({
          ...this.property,
          first_live_on: this.property.first_live_on ? moment(this.property.first_live_on).format("YYYY-MM-DD"): null,
        })
        if (data) {
          this.onClose({
            show: false,
            reload: true,
          })
        }
      },
  
      getTime(timeStr) {
        return moment(timeStr, this.dateFormat).toDate().getTime()
      },
    },
    computed: {},
  }
  </script>
  <style scoped>
  :deep() #full-screen-modal {
    padding: 0 !important;
    background: #f4f3ef;
  }
  
   #full-screen-modal :deep(.modal-dialog) {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    margin: 0;
    background: #f4f3ef;
  }
  
   #full-screen-modal :deep(.modal-content) {
    height: 100%;
    border: none;
    border-radius: 0;
    background: #f4f3ef;
  }
  
  :deep() #full-screen-modal .modal-header {
    border-radius: 0;
  }
  
  .modal-header-title {
    margin: 0;
    font-weight: bold;
  }
  
  .close {
    color: #ffffff;
    opacity: .8;
    padding-bottom: 20px !important;
    /*!* opacity: .5; */
  }
  
  .form-content label {
    font-weight: bold;
    font-size: 16px;
  }
  
  .capitalize:first-letter {
    text-transform: uppercase;
  }
  
  /*:deep() .mapboxgl-canvas {*/
  /*  width: 100% !important;*/
  /*  height: 100% !important;*/
  /*}*/
  </style>
  