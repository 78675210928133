<template>
  <b-modal v-model="showModal" id="full-screen-modal" size="huge" header-bg-variant="dark" header-text-variant="light"
           header-class="modal-header" footer-class="modal-footer" body-class="modal-body-full" :propertyId="propertyId">
    <template #modal-header>
      <h5 class="modal-header-title">{{ title }}</h5>
      <button type="button" class="close font-weight-bold" aria-label="Close" @click="onClose({ show: false })">×</button>
    </template>
    <div class="container">
      <b-form class="form-content" @submit.stop.prevent="onSubmit">
        <b-row class="mb-4">
          <b-col>
            <label>Property name:</label>
            <b-form-input v-model.trim="property.name" type="text" :state="validateState('name')"
              placeholder="Property name">
            </b-form-input>
            <b-form-invalid-feedback>Name is a required field and must be between [2 200] characters.
            </b-form-invalid-feedback>
          </b-col>
          <b-col :cols="property.status == 'promo' || property.status == 'presale' ? '8' : '6'">
            <b-row>
              <b-col v-if="canApprovePropertyStatus">
                <label>Property status:</label>
                <b-form-select v-model="property.status" :state="validateState('status')" :options="statusList">
                </b-form-select>
              </b-col>
              <b-col cols="8" v-if="property.status == 'promo' || property.status == 'presale'">
                <label class="capitalize">{{ property.status }} ends at:</label>
                <b-row v-if="property.status == 'promo'">
                  <b-col cols="8">
                    <b-form-datepicker v-model="picker.promo_date" class="mb-2"></b-form-datepicker>
                  </b-col>
                  <b-col cols="4">
                    <b-form-timepicker :hour12="false" v-model="picker.promo_time" locale="en"></b-form-timepicker>
                  </b-col>
                </b-row>
                <b-row v-if="property.status == 'presale'">
                  <b-col cols="8">
                    <b-form-datepicker v-model="picker.presale_date" class="mb-2"></b-form-datepicker>
                  </b-col>
                  <b-col cols="4">
                    <b-form-timepicker :hour12="false" v-model="picker.presale_time" locale="en"></b-form-timepicker>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-form-invalid-feedback>Status is a required field.</b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <label>Spc:</label>
            <b-form-select v-model="property.spc_id" :options="spcs" :state="validateState('spc_id')"
                           text-field="company_name" value-field="id">
              <template #first>
                <b-form-select-option :value="null">Select Spc</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback>Spc is a required field.</b-form-invalid-feedback>
          </b-col>
          <b-col cols="6">
            <label>Property type:</label>
            <b-form-select v-model="property.category_id" :state="validateState('category_id')" text-field="name"
              value-field="id">
              <template #first>
                <b-form-select-option :value="null">Select type</b-form-select-option>
              </template>
              <template v-for="category in categories">
                <b-form-select-option-group v-if="category.options && category.options.length" :label="category.name">
                  <b-form-select-option v-for="option in category.options" :key="option.id" :value="option.id">
                    {{ option.name }}
                  </b-form-select-option>
                </b-form-select-option-group>
                <b-form-select-option v-else :value="category.id">
                  {{ category.name }}
                </b-form-select-option>
              </template>

            </b-form-select>
            <b-form-invalid-feedback>Type is a required field.</b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <label>Property location:</label>
            <b-form-select v-model="property.location_id" :options="locations" :state="validateState('location_id')"
                           text-field="name" value-field="id">
              <template #first>
                <b-form-select-option :value="null">Select Location</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback>Location is a required field.</b-form-invalid-feedback>
          </b-col>
          <b-col cols="6">
            <label>Property address:</label>
            <b-form-input v-model.trim="property.metadata.address" type="text" placeholder="Property address">
            </b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <label>Property sqm:</label>
            <b-form-input v-model="property.sqm" :state="validateState('sqm')" type="number" placeholder="Property sqm">
            </b-form-input>
            <b-form-invalid-feedback>SQM is a required field.</b-form-invalid-feedback>
          </b-col>
          <b-col cols="3">
            <label>Property bed:</label>
            <b-form-input v-model="property.num_bed" type="number" placeholder="Property bed"></b-form-input>
          </b-col>
          <b-col cols="3">
            <label>Property bath:</label>
            <b-form-input v-model="property.num_bath" type="number" placeholder="Property bath"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <label>Property progress:</label>
            <b-input-group append="%">
              <b-form-input v-model="property.progress" :state="validateState('progress')" type="number" append=".00"
                placeholder="Property progress"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col cols="6">
            <b-row>
              <b-col>
                <label>Property latitude:</label>
                <b-form-input v-model="property.lat" type="number" placeholder="Property latitude"></b-form-input>
              </b-col>
              <b-col>
                <label>Property longitude:</label>
                <b-form-input v-model="property.lng" type="number" placeholder="Property longitude"></b-form-input>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <b-row>
              <b-col v-if="canApprovePropertyStatus">
                <label>Weight:</label>
                <b-form-input v-model="property.weight" :state="validateState('weight')" type="number"
                  placeholder="Weight">
                </b-form-input>
              </b-col>
              <b-col>
                <label>Total tokens:</label>
                <b-form-input v-model="property.total_tokens" :state="validateState('total_tokens')" type="number"
                  placeholder="Total tokens"></b-form-input>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6">
            <b-row>
              <b-col>
                <label>Sold tokens:</label>
                <b-form-input v-model="property.sold_tokens" :state="validateState('sold_tokens')" type="number"
                  placeholder="Sold tokens"></b-form-input>
              </b-col>
              <b-col>
                <label>Display sold tokens:</label>
                <b-form-input v-model="property.display_sold_tokens" :state="validateState('display_sold_tokens')"
                  type="number" placeholder="Display sold tokens"></b-form-input>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="6">
            <b-row>
              <b-col>
                <label>Property ERY:</label>
                <b-input-group append="%">
                  <b-form-input v-model="property.ery" :state="validateState('ery')" type="number" append=".00"
                    placeholder="Property ERY"></b-form-input>
                </b-input-group>
              </b-col>
              <b-col>
                <label>Property ECA:</label>
                <b-input-group append="%">
                  <b-form-input v-model="property.eca" :state="validateState('eca')" type="number" append=".00"
                    placeholder="Property ECA"></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="6">
            <b-row>
              <b-col>
                <label>Price Per Token (IDR):</label>
                <b-input-group>
                  <b-form-input v-model="property.price_per_token" :state="validateState('price_per_token')" type="number"
                    placeholder="Price per token (IDR)"></b-form-input>
                </b-input-group>
              </b-col>
              <b-col>
                <label>Minimum Purchase Token:</label>
                <b-input-group>
                  <b-form-input v-model="property.min_purchase_token" :state="validateState('min_purchase_token')"
                    type="number" placeholder="Minimum Purchase Tokem"></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mb-4">
          <b-col cols="6">
            <b-row>
              <b-col cols="12">
                <label>Display Milestone Count:</label>
                <b-form-input v-model="property.display_milestones_count" :state="validateState('display_milestones_count')" type="number"
                  placeholder="Display Milestone Count">
                </b-form-input>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6">
            <label>Map link:</label>
            <b-form-input v-model="property.map_link" placeholder="Map link">
            </b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-4 pt-4">
          <b-col cols="12">
            <location-picker v-model="map.location" ref="locationPicker" :apiKey="apiKey" :geo="map.geo"
              :searchForm="map.searchForm" :color="map.color" :initDelay="300" />
          </b-col>
        </b-row>
        <b-form-group class="pb-5">
          <b-form-checkbox id="checkbox-drag-drop" v-model="checkDragDrop" name="checkbox-drag-drop"
            value="enabled_drag_drop" unchecked-value="disabled_drag_drop">
            Enable Drag & Drop Reordering
          </b-form-checkbox>
          <VueFileAgent v-model="images" v-model:rawModelValue="images" ref="vueFileAgent" :theme="'grid'" :multiple="true"
            :deletable="checkDragDrop === 'disabled_drag_drop'"
            :sortable="checkDragDrop === 'enabled_drag_drop'"
            :meta="false" :accept="'image/*'" :maxSize="'3MB'" :maxFiles="10" :helpText="'Choose images files'"
            :errorText="{
                type: 'Invalid file type. Only images are allowed',
                size: 'Files should not exceed 3MB in size',
              }" @beforedelete="onBeforeDelete($event)" @select="filesSelected($event)">
          </VueFileAgent>
        </b-form-group>

        <b-tabs content-class="pt-2">
          <b-tab title="Detail" active class="bg-white">
            <editor :key="editorKey" :cloud-channel="tinyMCECloudChannel" id="editor-description" :api-key="tinyCloudKey"
              :init="editor" :initial-value="property.metadata.detail" model-events="change keydown blur focus paste"
              v-model="property.metadata.detail" />
          </b-tab>

          <b-tab title="Detail (Indonesian)" class="bg-white">
            <editor :key="editorKey" :cloud-channel="tinyMCECloudChannel" id="editor-description-id"
              :api-key="tinyCloudKey" :init="editor" :initial-value="property.metadata.detail_id_locale"
              model-events="change keydown blur focus paste" v-model="property.metadata.detail_id_locale" />
          </b-tab>

          <b-tab title="Financials" class="bg-white">
            <editor :key="editorKey" :cloud-channel="tinyMCECloudChannel" id="editor-financials" :api-key="tinyCloudKey"
              :init="editor" :initial-value="property.metadata.financial" model-events="change keydown blur focus paste"
              v-model="property.metadata.financial" />
          </b-tab>

          <b-tab title="Financials (Indonesian)" class="bg-white">
            <editor :key="editorKey" :cloud-channel="tinyMCECloudChannel" id="editor-financials-id"
              :api-key="tinyCloudKey" :init="editor" :initial-value="property.metadata.financial_id_locale"
              model-events="change keydown blur focus paste" v-model="property.metadata.financial_id_locale" />
          </b-tab>

          <b-tab title="Documents" class="bg-white">
            <div class="p-3">
              <div class="mb-2">Select New Files</div>
              <b-form-file class="btn btn-secondary btn-sm" v-model="documents" @input="onDocumentChange" multiple plain>
              </b-form-file>
              <div class="document-content">
                <ul>
                  <li v-for="thisFile in filesAccumulated">
                    <b-icon icon="file-earmark"></b-icon>
                    {{ thisFile.name }}
                  </li>
                </ul>
              </div>
              <div class="mb-4" v-if="currentDocuments.length">
                <div>Current Files</div>
                <div class="d-flex flex-row align-items-center mt-2" v-for="(item, index) in currentDocuments">
                  <button size="sm" type="button" class="btn-outline-main pl-3 pr-3 mr-2"
                    @click="removeCurrentDocument(index)">x</button>
                  <div>{{ item.file_name }}</div>
                </div>
              </div>
              <b-row class="mt-2">
                <b-col cols="10" lg="11">
                  <div>External Links</div>
                </b-col>
                <b-col cols="2" lg="1" class="text-right">
                  <button size="sm" type="button" class="btn-outline-main pl-4 pr-4 mr-2" @click="addLink">+</button>
                </b-col>
              </b-row>
              <LinkInput v-for="(item, index) in links" :link="item" :index="index" @removeLink="removeLink" />
            </div>
          </b-tab>

          <b-tab title="Market" class="bg-white">
            <editor :key="editorKey" :cloud-channel="tinyMCECloudChannel" id="editor-market" :api-key="tinyCloudKey"
              :init="editor" :initial-value="property.metadata.market" model-events="change keydown blur focus paste"
              v-model="property.metadata.market" />
          </b-tab>

          <b-tab title="Market (Indonesian)" class="bg-white">
            <editor :key="editorKey" :cloud-channel="tinyMCECloudChannel" id="editor-market-id" :api-key="tinyCloudKey"
              :init="editor" :initial-value="property.metadata.market_id_locale"
              model-events="change keydown blur focus paste" v-model="property.metadata.market_id_locale" />
          </b-tab>
        </b-tabs>

      </b-form>
    </div>
    <template #modal-footer>
      <b-button size="sm" variant="success" @click="onSubmit()">
        Save
      </b-button>
      <b-button size="sm" variant="primary" @click="onClose({ show: false })">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { useVuelidate } from "@vuelidate/core"
import { decimal, integer, maxLength, minLength, minValue, required } from "@vuelidate/validators"
import moment from "moment"
import Editor from "@anhoang/tinymce-vue"
import locationsService from "../../../services/locations.service"
import categoryService from "../../../services/categories.service"
import propertiesService from "../../../services/properties.service"
import spcsService from "../../../services/spcs.service"
import { getBase64, notify, urlImage, userHasPermission, getTinyMCEApiKey } from "../../../helpers/common"
import LocationPicker from "../../../components/Maps/LocationPicker.vue"
import LinkInput from "../../../components/LinkInput.vue"
import { PROPERTY_STATUSES_LIST } from "../../../constants/constants"
import { USER_PERMISSIONS } from "../../../constants/permissions"

export default {
  components: {
    LocationPicker,
    Editor,
    LinkInput
  },
  props: {
    show: {
      type: Boolean,
      default: false,

    },
    theme: {
      type: String,
      default: "light"
    },
    title: {
      type: String,
      default: ""
    },
    propertyId: {
      type: Number,
      default: null,
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      showModal: false,
      dateFormat: "YYYY-MM-DD HH:mm:ss",
      map: {
        location: [],
        geo: true,
        searchForm: true,
        height: 500,
        color: "#66615b",
        initDelay: 1000,
      },
      picker: {
        promo_date: null,
        promo_time: null,
        presale_date: null,
        presale_time: null,
      },
      property: {
        id: null,
        name: "",
        status: "draft",
        spc_id: null,
        category_id: null,
        location_id: null,
        num_bed: null,
        num_bath: null,
        sqm: null,
        lng: null,
        lat: null,
        documents: [],
        progress: null,
        weight: 0,
        ery: null,
        eca: null,
        total_tokens: 0,
        sold_tokens: 0,
        display_sold_tokens: 0,
        price_per_token: 0,
        min_purchase_token: 1,
        promo_ends_at: null,
        presale_ends_at: null,
        first_live_on: null,
        display_milestones_count: 5,
        metadata: {
          address: '',
          detail: '',
          detail_id_locale: '',
          financial: '',
          financial_id_locale: '',
          market: '',
          market_id_locale: '',
        },
        map_link: null,
      },
      images: [],
      documents: [],
      currentDocuments: [],
      deletedFileIds: [],
      links: [],
      filesAccumulated: [],
      spcs: [],
      locations: [],
      categories: [],
      statusList: PROPERTY_STATUSES_LIST,
      checkDragDrop: "disabled_drag_drop",
      editorKey: 0,
      tinyMCECloudChannel: '4',
      editor: {
        height: 500,
        menubar: true,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor textcolor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount"
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor forecolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help",
      },
    }
  },
  validations() {
    return {
      property: {
        name: {
          required,
          minLength: minLength(2),
          mexLength: maxLength(200)
        },
        status: {
          required
        },
        category_id: {
          required,
        },
        spc_id: {
          required,
        },
        location_id: {
          required,
        },
        num_bed: {
          integer,
        },
        num_bath: {
          integer,
        },
        sqm: {
          required,
          integer,
        },
        progress: {
          decimal,
        },
        ery: {
          decimal,
        },
        eca: {
          decimal,
        },
        weight: {
          integer,
        },
        total_tokens: {
          integer,
        },
        sold_tokens: {
          integer,
        },
        display_sold_tokens: {
          integer,
        },
        price_per_token: {
          integer,
        },
        min_purchase_token: {
          integer,
          minValue: minValue(1),
        },
        promo_ends_at: {
          integer,
        },
        presale_ends_at: {
          integer,
        },
        display_milestones_count: {
          integer,
        },
      },
      links: {
        $each: {
          link: {
            required
          },
          icon: {
            required
          },
          name: {
            required
          },
        }
      }
    }
  },
  watch: {
    "map.location"(v) {
      this.property.lat = v[1]
      this.property.lng = v[0]
    },
    async propertyId(v) {
      if (v) {
        this.property = await propertiesService.getById(v)
        this.editorKey += 1
      }
    },
    show(value) {
      this.showModal = value
      if (value) {
        setTimeout(() => {
          this.editorKey += 1
        }, 1000)
      }
    },
    'property.images': {
    handler(values) {
      if (values && values.length) {
        const images = []
        values.forEach(v => {
          if (v.image !== "") {
            const name = v.image.split("/").pop()
            const type = name.split(".").pop()
            images.push({
              id: v.id,
              tempName: name,
              name: name,
              url: urlImage(v),
              path: urlImage(v),
              size: 0,
              type: `image/${type}`,
            })
          }
        })
        this.images = images
      }
    },
    deep: true
  },
    "property.documents"(values) {
      if (values && values.length) {
        const links = [];
        values.forEach(v => {
          if (v.id && v.file_name) {
            if (v.type === 'LINK') {
              links.push({
                name: v.file_name,
                link: v.link,
                icon: v.icon,
              })
            }
          }
        })
        this.currentDocuments = values.filter(e => e.type === 'FILE')
        this.links = links
      }
    },
    "property.promo_ends_at"(value) {
      if (value) {
        const str = moment(value).format(this.dateFormat).split(" ")
        this.picker.promo_date = str[0]
        this.picker.promo_time = str[1]
      } else {
        this.picker.promo_date = null
        this.picker.promo_time = null
      }
    },
    "property.presale_ends_at"(value) {
      if (value) {
        const str = moment(value).format(this.dateFormat).split(" ")
        this.picker.presale_date = str[0]
        this.picker.presale_time = str[1]
      } else {
        this.picker.presale_date = null
        this.picker.presale_time = null
      }
    },
    "picker.promo_date"(value) {
      const time = this.getTime(`${value} ${this.picker.promo_time}`)
      if (time) {
        this.property.promo_ends_at = time
      }
    },
    "picker.promo_time"(value) {
      const time = this.getTime(`${this.picker.promo_date} ${value}`)
      if (time) {
        this.property.promo_ends_at = time
      }
    },
    "picker.presale_date"(value) {
      console.log(value)
      const time = this.getTime(`${value} ${this.picker.presale_time}`)
      if (time) {
        this.property.presale_ends_at = time
      }
    },
    "picker.presale_time"(value) {
      const time = this.getTime(`${this.picker.presale_date} ${value}`)
      if (time) {
        this.property.presale_ends_at = time
      }
    },
  },
  async mounted() {
    await Promise.all([
      this.getSpcs(),
      this.getLocations(),
      this.getCategories(),
    ])
  },
  methods: {
    onReset() {
      // Reset our form values
      this.property.id = null
      this.property.name = ""
      this.property.status = ""
      this.property.spc_id = null
      this.property.location_id = null
      this.property.category_id = null
      this.property.num_bed = null
      this.property.num_bath = null
      this.property.sqm = null
      this.property.lng = null
      this.property.lat = null
      this.property.documents = []
      this.property.images = []
      this.property.progress = null
      this.property.ery = null
      this.property.eca = null
      this.property.weight = 0
      this.property.total_tokens = 0
      this.property.sold_tokens = 0
      this.property.display_sold_tokens = 0
      this.property.price_per_token = 0
      this.property.min_purchase_token = 1
      this.property.promo_ends_at = null
      this.property.presale_ends_at = null
      this.property.first_live_on = null
      this.property.map_link = null
      this.property.display_milestones_count = 5
      this.documents = []
      this.currentDocuments = []
      this.deletedFileIds = []
      this.links = []
      this.filesAccumulated = []
      this.images = []
      this.checkDragDrop = "disabled_drag_drop"
      this.property.metadata = {
        address: '',
        detail: '',
        detail_id_locale: '',
        financial: '',
        financial_id_locale: '',
        market: '',
        market_id_locale: '',
      }
    },
    notAfterToday(date) {
      // const today = new Date().setHours(0, 0, 0, 0)
      return false //date > today
    },
    validateState(name) {
      const { $dirty, $error } = this.v$.property[name]
      return $dirty ? !$error : null
    },
    onClose: function (v) {
      this.onReset()
      this.$emit("onClose", v)
    },
    async onSubmit() {
      this.v$.$touch();
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        window.scrollTo(0, 0)
        return;
      }
      this.property.documents = []
      const uploadImages = this.images.map((e, index) => {
        if (e.id) {
          return {
            id: e.id,
            path: e.path,
            name: e.tempName,
            weight: index + 1,
          }
        }
        return {
          path: e.path,
          name: e.file.name,
          isNew: true,
          weight: index + 1,
        }
      })
      if (!uploadImages || !uploadImages.length) {
        notify({ text: "Property images is required", type: "error" })
        return
      }
      const values = []
      for (const file of this.documents) {
        if (file.name) {
          const base64 = await getBase64(file)
          if (base64) {
            values.push({
              name: file.name,
              data: base64
            })
          }
        }
      }
      if (values.length) {
        this.property.documents = values
      }

      const data = await propertiesService.save({
        ...this.property,
        links: this.links,
        images: uploadImages,
        deleted_file_ids: this.deletedFileIds,
      })
      if (data) {
        this.onClose({
          show: false,
          reload: true,
        })
      }
    },
    onDocumentChange(event) {
      const values = []
      this.documents.forEach(thisFile => {
        values.push({
          name: thisFile.name
        })
      })
      this.filesAccumulated = values
    },
    async getSpcs () {
      const response = await spcsService.getSpcs()
      if (response && response.data) {
        this.spcs = response.data
      }
    },
    async getLocations() {
      this.locations = await locationsService.getLocations()
    },
    async getCategories() {
      this.categories = await categoryService.getCategories()
    },

    getTime(timeStr) {
      return moment(timeStr, this.dateFormat).toDate().getTime()
    },

    onBeforeDelete(fileRecord) {
      const index = this.images.indexOf(fileRecord)
      if (index !== -1) {
        this.images.splice(index, 1)
      }
    },

    async filesSelected(fileRecords) {
      if (this.images.length) {
        for (let i = 0; i < this.images.length; i++) {
          if (!this.images[i].id && !this.images[i].path) {
            this.images[i].path = await getBase64(this.images[i].file)
          }
        }
      }
    },

    addLink() {
      this.links = [...this.links, {}]
    },

    removeLink(index) {
      this.links.splice(index, 1)
    },

    removeCurrentDocument(index) {
      const deleted = this.currentDocuments.splice(index, 1)
      if (deleted.length) {
        this.deletedFileIds.push(deleted[0].id)
      }
    }
  },
  computed: {
    apiKey() {
      return process.env.VUE_APP_MAP_KEY
    },

    tinyCloudKey() {
      return getTinyMCEApiKey()
    },
    canApprovePropertyStatus() {
      return userHasPermission(USER_PERMISSIONS.PROPERTY.APPROVE_PROPERTY_STATUS_AND_TOKEN)
    },
  },
}
</script>
<style scoped>
.modal-header-title {
  margin: 0;
  font-weight: bold;
}

.close {
  color: #ffffff;
  opacity: .8;
  padding-bottom: 20px !important;
  /*!* opacity: .5; */
}

.images-picker {
  width: 60%;
}

.document-content {
  margin: 20px 0;
  padding-bottom: 20px;
}

.document-content li {
  list-style: none;
  color: #3a283d;
  margin: 3px 0;
  font-size: 16px;
}

.form-content label {
  font-weight: bold;
  font-size: 16px;
}

.capitalize:first-letter {
  text-transform: uppercase;
}

/*:deep() .mapboxgl-canvas {*/
/*  width: 100% !important;*/
/*  height: 100% !important;*/
/*}*/
</style>
