import { createApp, configureCompat } from 'vue'
import App from "./App";
import router from "./router/index";
import store from './store/store';
import errorHandler from './plugins/errorHandler'
import plugins from "./plugins";
import i18n from "./i18n";
import 'leaflet/dist/leaflet.css';
import { SlickList, SlickItem } from 'vue-slicksort';
import VueAnalytics from 'vue-analytics';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import L from "leaflet"
import VueFileAgentNext from '@nhat292/vue-file-agent-next'
import '@nhat292/vue-file-agent-next/dist/vue-file-agent-next.css'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import { createGtm } from '@gtm-support/vue-gtm';
import JsonCSV from 'vue-json-csv';
import excel from 'vue-excel-export';
import Popper from "vue3-popper"


const VueApp = createApp(App)

VueApp.use(plugins);
VueApp.use(VueFileAgentNext);
VueApp.use(DatePicker);
VueApp.use(createMetaManager());
VueApp.use(metaPlugin);
VueApp.component('multiselect', Multiselect)
VueApp.use(VueAnalytics, { id: process.env.VUE_APP_GOOGLE_ANALYTIC_TRACKING_ID, router });
VueApp.component('vfa-sortable-list', SlickList);
VueApp.component('vfa-sortable-item', SlickItem);
VueApp.component('downloadCsv', JsonCSV);
VueApp.component("Popper", Popper);
VueApp.use(excel);
window.store = store;
VueApp.config.errorHandler = errorHandler;
VueApp.use(router);
VueApp.use(i18n);
VueApp.use(store);

VueApp.use(createGtm({
  id: [
    {
      id: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID || '',
      queryParams: {
        // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
        gtm_auth: process.env.VUE_APP_GOOGLE_TAG_AUTH,
        gtm_preview: process.env.VUE_APP_GOOGLE_TAG_PREVIEW,
        gtm_cookies_win: 'x',
      },
    },
    {
      id: process.env.VUE_APP_GOOGLE_TAG_MANAGER_AFRA_ID || '',
      queryParams: {
        // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
        gtm_auth: process.env.VUE_APP_GOOGLE_TAG_AUTH_AFRA,
        gtm_preview: process.env.VUE_APP_GOOGLE_TAG_PREVIEW_AFRA,
        gtm_cookies_win: 'x',
      },
    }
  ],
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  enabled: process.env.VUE_APP_ENV === 'production' || process.env.VUE_APP_ENV === 'staging', // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  trackOnNextTick: false
}));

// Add navigation guard for tracking page views with pageId
router.afterEach((to) => {
  const pageId = to.meta.id || 'default_page_id' // Default to 'default_page_id' if not present
  VueApp.prototype.$gtm.trackView(to.name, to.path, {
    event: 'Page View',
    pageId: pageId,
  })
})

configureCompat({
    MODE: 2,
    // required by Vue-router
    CONFIG_OPTION_MERGE_STRATS: 'suppress-warning',
    GLOBAL_PRIVATE_UTIL: 'suppress-warning',
    GLOBAL_PROTOTYPE: 'suppress-warning',

    // required due to global mixin on vue-router
    INSTANCE_EVENT_HOOKS: 'suppress-warning',
    OPTIONS_DESTROYED: 'suppress-warning',
    INSTANCE_EVENT_EMITTER: 'suppress-warning',

    // required by portal-vue
    GLOBAL_SET: 'suppress-warning',

    // globals
    GLOBAL_EXTEND: 'suppress-warning',
    GLOBAL_MOUNT: 'suppress-warning',

    // functional does not work how you think ;)
    COMPONENT_FUNCTIONAL: 'suppress-warning',
 })
VueApp.mount('#app')



