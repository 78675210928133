import DashboardLayout from "../layout/dashboard/DashboardLayout.vue"
import Dashboard from "../pages/admin/Dashboard.vue"
import Properties from "../pages/admin/Properties.vue"
import Payments from "../pages/admin/Payments.vue"
import Orders from "../pages/admin/Orders"
import SellTokenRequests from "../pages/admin/SellTokenRequests"
import Waitlist from "../pages/admin/Waitlist"
import Config from "../pages/admin/Config"
import Role from "../pages/admin/Role"
import AdminUser from "../pages/admin/AdminUser"
import Referral from "../pages/admin/Referral"
import Location from "../pages/admin/Location"
import Category from "../pages/admin/Category"
import Withdrawal from "../pages/admin/Withdrawal"
import EmailTemplate from "../pages/admin/EmailTemplate"
import Email from "../pages/admin/Email"
import EmailBroadcast from "../pages/admin/EmailBroadcast"
import User from "../pages/admin/User"
import roles from "../constants/roles"
import RentalDistributions from "../pages/admin/RentalDistributions"
import RentalDistributionDetails from "../pages/admin/RentalDistributionDetails"
import RentalDeductionTypes from "../pages/admin/RentalDeductionTypes"
import Banner from "../pages/admin/Banner"
import VirtualRentalDistributionDetails from "../pages/admin/VirtualRentalDistributionDetails"
import ContractTemplates from "../pages/admin/ContractTemplates.vue"
import Spcs from "../pages/admin/Spcs"
import PropertyDashboard from "../pages/admin/PropertyDashboard.vue"
import TwoFactorAuth from "../pages/admin/TwoFactorAuth.vue"
import StatisticNumbers from "../pages/admin/StatisticNumbers"
import RmDashboard from "../pages/admin/RmDashboard.vue"
import Maintenance from "../pages/admin/Maintenance.vue"

const admin = [
  {
    path: "/goroadmin",
    component: DashboardLayout,
    redirect: "/goroadmin/dashboard",
    name: "goroadmin",
    meta: {
      authorize: [roles.Admin],
    },
    children: [
      {
        path: "dashboard",
        name: "adminDashboard",
        component: Dashboard,
      },
      {
        path: "property-dashboard",
        name: "propertyDashboard",
        component: PropertyDashboard,
      },
      {
        path: "properties",
        name: "adminProperties",
        component: Properties
      },
      {
        path: "payments",
        name: "adminPayments",
        component: Payments
      },
      {
        path: "orders",
        name: "adminOrders",
        component: Orders
      },
      {
        path: "sell-token-request",
        name: "adminSellTokenRequests",
        component: SellTokenRequests
      },
      {
        path: "contractTemplates",
        name: "adminContractTemplates",
        component: ContractTemplates
      },
      {
        path: "waitlists",
        name: "adminWaitlists",
        component: Waitlist
      },
      {
        path: "configs",
        name: "adminConfigs",
        component: Config
      },
      {
        path: "roles",
        name: "adminRoles",
        component: Role
      },
      {
        path: "admin-users",
        name: "adminAdminUsers",
        component: AdminUser
      },
      {
        path: "referrals",
        name: "adminReferrals",
        component: Referral
      },
      {
        path: "spcs",
        name: "adminSpcs",
        component: Spcs
      },
      {
        path: "locations",
        name: "adminLocations",
        component: Location
      },
      {
        path: "categories",
        name: "adminCategories",
        component: Category
      },
      {
        path: "users",
        name: "adminUsers",
        component: User
      },
      {
        path: "withdrawals",
        name: "adminWithdrawals",
        component: Withdrawal
      },
      {
        path: "email-templates",
        name: "emailTemplates",
        component: EmailTemplate
      },
      {
        path: "emails",
        name: "emails",
        component: Email
      },
      {
        path: "email-broadcast",
        name: "emailBroadcast",
        component: EmailBroadcast
      },
      {
        path: "rentalDistributions",
        name: "adminRentalDistributions",
        component: RentalDistributions
      },
      {
        path: "rentalDistributionDetails",
        name: "adminRentalDistributionDetails",
        component: RentalDistributionDetails
      },
      {
        path: "virtual-rental-distribution-details",
        name: "adminVirtualRentalDistributionDetails",
        component: VirtualRentalDistributionDetails
      },
      {
        path: "rentalDeductionTypes",
        name: "adminRentalDeductionTypes",
        component: RentalDeductionTypes
      },
      {
        path: 'banners',
        name: 'banners',
        component: Banner,
      },
      {
        path: 'statistic-numbers',
        name: 'adminStatisticNumbers',
        component: StatisticNumbers,
      },
      {
        path: 'twofa',
        name: 'twofa',
        component: TwoFactorAuth,
      },
      {
        path: 'rm-dashboard',
        name: 'rmDashboard',
        component: RmDashboard,
      },
      {
        path: 'maintenance',
        name: 'maintenance',
        component: Maintenance,
      }
    ]
  }
]

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
 function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default admin
