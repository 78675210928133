import serverApi from "../../utils/serverApi";

const propertiesService = {
  getOwnedByUser: (id) =>
    serverApi({ uri: `properties/owned-by-user/${id}`, method: "GET" }),

  getHolders: (id, data) =>
    serverApi({ uri: `properties/holders/${id}`, method: "GET", data }),

  getVirtualOwnedByUser: (id) =>
    serverApi({ uri: `properties/virtual-owned-by-user/${id}`, method: "GET" }),

  getMilesStones: (data) =>
    serverApi({
      uri: "properties/milestones/get-all",
      method: "GET",
      data,
    }),

  updateMilesStones: (data, isLoading = true, isNotify = true) =>
    serverApi({
      uri: "properties/milestones",
      method: "POST",
      data,
      isLoading,
      isNotify,
    }),

  getFinancials: (data) =>
    serverApi({
      uri: "properties/financials/get-all",
      method: "GET",
      data,
    }),

  updateFinancials: (data, isLoading = true, isNotify = true) =>
    serverApi({
      uri: "properties/financials",
      method: "POST",
      data,
      isLoading,
      isNotify,
    }),
    
};

export default propertiesService;
