<template>
  <header class="site-header site-navbar fixed-top" role="banner" style="z-index: 10000;">
    <location-reminder class="site-navbar-banner"></location-reminder>
    <div v-if="isShowBanner" class="site-navbar-banner goro-text-banner-section">
      <div class="content-banner">
        <p class="text font-18">{{ $t("TEXT_BANNER.TEXT") }} - <a
          href="https://www.channelnewsasia.com/the-big-spark/season1"
          target="_blank">{{ $t("TEXT_BANNER.WATCH_HERE") }}</a></p>
      </div>
    </div>
    <div class="row align-items-center position-relative justify-content-between pt-2 pb-2" style="height: 100%;">
      <div class="col-7 pl-0 pr-0">
        <nav class="site-navigation" role="navigation">
          <ul class="site-menu d-none d-lg-block">
            <router-link class="nav-link pt-0 pb-0 mr-4" to="/">
              <img class="logo-img" src="../../assets/img/logo_with_text.png" alt="">
            </router-link>
            <li><router-link exact-active-class="active" class="nav-link" to="/">{{$t("HEADER.HOME")}}</router-link></li>
            <li><router-link exact-active-class="active" class="nav-link" :to="{name: 'marketplace'}">{{$t("HEADER.MARKETPLACE")}}</router-link></li>
            <li><router-link exact-active-class="active" class="nav-link" :to="{name: 'aboutUs'}">{{$t("HEADER.ABOUT_US")}}</router-link></li>
           <!-- <li><a :href=sellMyProperty target="_blank" class="nav-link">{{ $t("HEADER.SELL_MY_PROPERTY") }}</a></li> -->
           <li><a :href=faqUrl target="_blank" class="nav-link" @click="trackGtmEvent(gtmEvent.FAQS)" >FAQ</a></li>
          </ul>
        </nav>
      </div>
      <div class="col-5 pl-0 pr-0">
        <nav class="site-navigation text-right" role="navigation">
          <ul class="site-menu d-none d-lg-block">
            <li><locale-switcher/></li>
            <div class="d-flex flex-row" style="float:right;margin-right: 10px;">
              <div class="notification-container">
                <notification/>
              </div>
              <user-avatar/>
            </div>
            <li v-if="!userProfile">
              <router-link exact-active-class="active" class="nav-link pl-1 pr-2" :to="{name: 'login'}">
                <b-button id="btn_header_Login" class="btn-outline-main pt-1 pb-1 pl-3 pr-3" type="submit" variant="none">
                  {{ $t("AUTH.LOGIN") }}
                </b-button>
              </router-link>
            </li>
            <li v-if="!userProfile">
              <router-link class="pl-1 mr-4" :to="{name: 'register'}">
                <b-button id="btn_header_GetStarted" class="btn-main pt-1 pb-1" type="submit" variant="none" @click="trackGtmEvent(gtmEvent.GET_STARTED)">
                  {{ $t("AUTH.GET_STARTED") }}
                </b-button>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>

      <!--Header for Mobile-->
      <div class="site-logo d-block d-lg-none">
        <router-link to="/">
          <img class="logo-img" src="../../assets/img/logo_with_text.png" alt="">
        </router-link>
      </div>
      <div class="notification-container-mb mr-4 mb-3">
        <notification/>
      </div>
      <div class="menu-mobile toggle-button d-inline-block d-lg-none">
        <div class="bm-burger-button" @click="toggle" v-b-toggle.sidebar-backdrop>
          <span class="bm-burger-bars line-style" style="top: 0;"></span>
          <span class="bm-burger-bars line-style" style="top: 40%;"></span>
          <span class="bm-burger-bars line-style" style="top: 80%;"></span>
        </div>
        <b-sidebar id="sidebar-backdrop" v-if="open" @hidden="closeMenu" no-close-on-route-change right shadow width="100%" sidebar-class="mobile-sidebar-wrapper">
          <template #header="{ hide }">
            <div class="mobile-header-content">
              <router-link to="/">
                <img class="logo-img" src="../../assets/img/logo_with_text.png" alt="">
              </router-link>
              <b-button id="btn_header_HideMenu" class="close" @click="hide">
                <img class="logo-img" src="../../assets/img/icons/close-sidebar.png" alt="">
              </b-button>
            </div>
          </template>
          <div class="menu-content text-center">
            <ul class="site-menu site-navigation ml-auto d-lg-block">
              <li><router-link v-b-toggle.sidebar-backdrop exact-active-class="active" class="nav-link" to="/">{{$t("HEADER.HOME")}}</router-link></li>
              <li><router-link v-b-toggle.sidebar-backdrop exact-active-class="active" class="nav-link" :to="{name: 'marketplace'}">{{$t("HEADER.MARKETPLACE")}}</router-link></li>
              <li><router-link v-b-toggle.sidebar-backdrop exact-active-class="active" class="nav-link" :to="{name: 'aboutUs'}">{{$t("HEADER.ABOUT_US")}}</router-link></li>
              <!-- <li><a :href=sellMyProperty target="_blank" v-b-toggle.sidebar-backdrop class="nav-link">{{ $t("HEADER.SELL_MY_PROPERTY") }}</a></li> -->
              <li><a :href=faqUrl target="_blank" v-b-toggle.sidebar-backdrop class="nav-link">FAQ</a></li>
              <li><locale-switcher/></li>
              <li><user-avatar v-b-toggle.sidebar-backdrop/></li>
              <li v-if="!userProfile">
                <router-link v-b-toggle.sidebar-backdrop exact-active-class="active" class="nav-link" :to="{name: 'login'}">
                  <b-button id="btn_sidebar_Login" class="btn-outline-main mt-1 mb-1 pl-3 pr-3" type="submit" variant="none">
                    {{ $t("AUTH.LOGIN") }}
                  </b-button>
                </router-link>
              </li>
              <li v-if="!userProfile">
                <router-link :to="{name: 'register'}" v-b-toggle.sidebar-backdrop>
                  <b-button id="btn_sidebar_GetStarted" class="btn-main mt-1 mb-1" type="submit" variant="none" @click="trackGtmEvent(gtmEvent.GET_STARTED)">
                    {{ $t("AUTH.GET_STARTED") }}
                  </b-button>
                </router-link>
              </li>
            </ul>
          </div>
        </b-sidebar>
      </div>
    </div>
  </header>
</template>
<script>
import LocationReminder from "./LocationReminder"
import UserAvatar from "@/components/UserAvatar"
import Notification from "@/components/Notification"
import LocaleSwitcher from "@/components/LocaleSwitcher"
import Drawer from "vue-simple-drawer"
import externalSites from "@/constants/externalSites"
import { gtmTrackEvent } from "../../helpers/gtm"
import { GTM_EVENT_NAMES } from "../../constants/gtm"

export default {
  components: {
    LocationReminder,
    UserAvatar,
    LocaleSwitcher,
    Drawer,
    Notification,
  },
  data() {
    return {
      open: false,
      sellMyProperty: externalSites.MAIL_TO.SELL_MY_PROPERTY,
      faqUrl: externalSites.FAQ,
      gtmEvent: {
        GET_STARTED: GTM_EVENT_NAMES.GET_STARTED,
        FAQS: GTM_EVENT_NAMES.FAQS,
      }
    }
  },
  computed: {
    userProfile () {
      return this.$store.getters.userProfile
    },
    isShowBanner () {
      return this.$route.name === "home" || this.$route.name === "homeForeigner"
    },
  },
  methods: {
    toggle() {
      this.open = !this.open
    },
    closeMenu() {
      this.open = false
    },
    trackGtmEvent(event) {
      gtmTrackEvent({
        event: event,
      })
    },
  },
  watch: {
    isShowBanner (value) {
      this.$nextTick(() => {
        window.dispatchEvent(new Event("resize"))
      })
    },
  },
}
</script>
<style lang="scss">
  .hide-header-footer .site-navbar {
    display: none;
  }
  .site-navbar {
    margin-bottom: 0;
    z-index: 99;
    position: relative;
    width: 100%;
    padding: 0 5rem;
    border-bottom: 1px solid #ccc;
  }

  .site-navbar .site-navbar-banner {
    width: 100vw;
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .site-navbar .toggle-button {
    position: absolute;
    right: 0;
  }

  .site-navbar .site-logo {
    position: absolute;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 0;
    margin: 0;
    padding: 0;
    z-index: 3;
  }

  @media (max-width: 991.98px) {
    .site-navbar .site-logo {
      float: left;
      position: relative;
    }
  }

  .site-navbar .site-navigation.border-bottom {
    border-bottom: 1px solid white !important;
  }

  .site-navbar .site-navigation .site-menu {
    padding: 0;
    margin-bottom: 0;
  }

  .site-navbar .site-navigation .site-menu a {
    text-decoration: none !important;
    display: inline-block;
    padding-left: 5px;
  }

  .site-navbar .site-navigation .site-menu > li {
    display: inline-block;
  }

  .site-navbar .site-navigation .site-menu > li > a {
    padding: 20px 12px;
    color: var(--primary-color);
    display: inline-block;
    text-decoration: none !important;
    font-size: 16px;
    font-weight: 600;

    &:hover {
      color: var(--primary-darker-color);
    }
  }

  .site-navbar .site-navigation .site-menu .active {
    color: var(--primary-darker-color);
  }

  .site-navbar .site-navigation .site-menu .has-children {
    position: relative;
  }

  .site-navbar .site-navigation .site-menu .has-children > a {
    position: relative;
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown {
    visibility: hidden;
    opacity: 0;
    top: 100%;
    position: absolute;
    text-align: left;
    border-top: 2px solid #143863;
    -webkit-box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
    padding: 0 0;
    margin-top: 20px;
    margin-left: 0;
    background: #fff;
    -webkit-transition: 0.2s 0s;
    -o-transition: 0.2s 0s;
    transition: 0.2s 0s;
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
    position: absolute;
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px;
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown a {
    text-transform: none;
    letter-spacing: normal;
    -webkit-transition: 0s all;
    -o-transition: 0s all;
    transition: 0s all;
    color: var(--primary-color) !important;
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown a.active {
    color: var(--primary-darker-color);
    background: #f8f9fa;
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown > li {
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 200px;
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown > li > a {
    padding: 9px 20px;
    display: block;
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown > li > a:hover {
    background: #f8f9fa;
    color: var(--primary-color);
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > .dropdown, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > ul {
    left: 100%;
    top: 0;
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:hover > a, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:active > a, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:focus > a {
    background: #f8f9fa;
    color: var(--primary-color);
  }
  @media (max-width: 991.98px) {
    .site-navbar {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  .site-navbar .toggle-button {
    position: absolute;
    right: 0;
  }

  @media (max-width: 991.98px) {
    .site-navbar .site-logo {
      float: left;
      position: relative;
    }
  }

  .site-navbar .site-navigation.border-bottom {
    border-bottom: 1px solid white !important;
  }

  .site-navbar .site-navigation .site-menu a {
    text-decoration: none !important;
    display: inline-block;
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown {
    visibility: hidden;
    opacity: 0;
    top: 100%;
    position: absolute;
    text-align: left;
    border-top: 2px solid #143863;
    -webkit-box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
    padding: 0 0;
    margin-top: 20px;
    margin-left: 0;
    background: #fff;
    -webkit-transition: 0.2s 0s;
    -o-transition: 0.2s 0s;
    transition: 0.2s 0s;
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
    position: absolute;
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
    display: none;
    bottom: 100%;
    left: 20%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px;
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown a {
    text-transform: none;
    letter-spacing: normal;
    -webkit-transition: 0s all;
    -o-transition: 0s all;
    transition: 0s all;
    color: var(--primary-color) !important;
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown a.active {
    color: var(--primary-darker-color);
    background: #f8f9fa;
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown > li {
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 200px;
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > .dropdown, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > ul {
    left: 100%;
    top: 0;
  }

  .site-navbar .site-navigation .site-menu .has-children:hover > a, .site-navbar .site-navigation .site-menu .has-children:focus > a, .site-navbar .site-navigation .site-menu .has-children:active > a {
    color: var(--primary-darker-color);
  }

  .site-navbar .site-navigation .site-menu .has-children:hover, .site-navbar .site-navigation .site-menu .has-children:focus, .site-navbar .site-navigation .site-menu .has-children:active {
    cursor: pointer;
  }

  .site-navbar .site-navigation .site-menu .has-children:hover > .dropdown, .site-navbar .site-navigation .site-menu .has-children:focus > .dropdown, .site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
    margin-top: 0;
    visibility: visible;
    opacity: 1;
  }

  .notification-container-mb {
    display: block;
    margin-top: 17px;
  }

  @media (min-width: 992px) {
    .notification-container-mb {
      display: none;
      margin-top: 17px;
    }
  }

  .notification-container {
    display: block;
    margin-top: 13px;
  }

  .mobile-sidebar-wrapper{
    header{
      border-bottom: 1px solid #EFECEC;
      justify-content: center;
      padding-bottom: 0.2rem !important;
      .mobile-header-content{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        button.close{
          position: absolute;
          top: 15px;
          right: 0px;
          width: 14px;
          height: 14px;
          background: transparent;
          opacity: 1;
          &:hover{
            opacity: .8;
          }
          img{
            width: 12px;
            height: 12px;
          }
        }
      }
    }
    .b-sidebar-body{
      .menu-content{
        ul.site-menu{
          padding: 0 !important;
          li{
            font-size: 18px;
          }
        }
      }
    }
  }
</style>
